import React from "react"
import Page from "./../templates/page.js"
import { Helmet } from 'react-helmet'
import ContactForm from "./../components/form.js"
import { Link } from "gatsby"
import { Icon, Emoji } from "./../components/helpers.js"
import { TiHome } from "react-icons/ti";

export default () => {

    const main = <>
        <h2>Contact</h2>

        <p>If you have any questions, suggestions or comments on one of my projects, I'd be happy to get in touch with you <Emoji e="😊" />.</p>
        <ContactForm />
    </>;

    const side = <div className="about"><h2>Inquiries</h2>

        <p>If you are interested in one of my projects, or would like to have a copy of your own, just let me know.</p>

        <p>I am also open to doing custom project or design work. Don't hesitate to reach out if you have any ideas!</p>

    </div>;

    return (
        <>
            <Helmet>
                <title>Contact - Maakbaas</title>
                <meta name="description" content="I am an engineer by day, maker by night, who likes reinventing the wheel. If you have any comments on my projects, I'd be happy to get in touch with you." />
            </Helmet>
            <Page title={'Contact me'} content={main} sidebar={side}
                sidebar2={<p><Icon><TiHome /></Icon> <Link to="/">Back to home</Link></p>}  />
        </>);

}